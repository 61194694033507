import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <p>目前共有四条自建的 API：</p>
    <ol>
      <li><span role="img" aria-label="talk">💬</span><Link to="/hitokoto/">一言（Hitokoto）</Link></li>
      <li><span role="img" aria-label="music">🎵</span><Link to="/cloudmusic/">网易云（Netease Cloud Music）</Link></li>
      <li><span role="img" aria-label="statics">📈</span><Link to="/gaviews/">Google 分析（Google Analytics）</Link></li>
      <li><span role="img" aria-label="statics">🔍</span><Link to="/blog-search/">博客搜索（Blog Search）</Link></li>
    </ol>
  </Layout>
)

export default IndexPage
